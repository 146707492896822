import { Box, Container, Grid, Typography } from '@mui/material';
import registerIllustration from 'assets/images/registerIllustration.svg';
import Link from 'components/Link';
import podLogo from 'assets/images/pod-logo.svg';

import { ToastContainer } from 'react-toastify';
import { Login } from 'components/v2/Login';
// import { useRedirectAuthorizedPaths } from 'hooks';

const LoginPage = () => {
  // useRedirectAuthorizedPaths();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      flexGrow={1}
      bgcolor="#F3F9EC"
      // maxHeight="40vh"
    >
      <ToastContainer />
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            item
            md={6.5}
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          >
            <Box
              sx={{
                '& img': {
                  maxWidth: '100%',
                  maxHeight: '100%',
                },
              }}
            >
              <img src={registerIllustration.src} alt="" />
            </Box>
          </Grid>
          <Grid item md={5.5} xs={12}>
            <Box
              sx={{
                pl: {
                  xs: 'inherit',
                  md: '110px',
                },
              }}
            >
              <Box
                display="flex"
                sx={{
                  '& a': {
                    mr: 2,
                  },
                }}
              >
                <Link href="/faqs" noLinkStyle>
                  <Typography>FAQs</Typography>
                </Link>
                <Link href="/pod-cafe" noLinkStyle>
                  <Typography>POD Cafe</Typography>
                </Link>
                <Link href="/about" noLinkStyle>
                  <Typography>About</Typography>
                </Link>
              </Box>
              <Box mt={7}>
                <img src={podLogo.src} alt="pod logo" />
              </Box>
              <Login />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LoginPage;
